.container {
  display: flex;
  padding: 0px var(--spacing-12);
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  background: var(--color-grey-light-2);
  border-radius: 0 0 var(--spacing-8) var(--spacing-8);
}

.button {
  &:global(.MuiButtonBase-root) {
    color: var(--color-primary-50);
    font-family: Poppins;
    font-size: var(--spacing-16);
    font-weight: 500;
    line-height: var(--spacing-16);
    letter-spacing: 0.5px;
    text-transform: none;
    padding: 8px 10px;
  }
}
