:root {
  --color-primary: #2196f3;
  --color-primary-50: #0084ff;
  --color-primary-40: #006acc;
  --color-primary-30: #004f99;
  --color-primary-20: #003566;
  --color-blue-selected: #1976d2;
  --color-primary-selected: #004f99;
  --color-primary-selected-background: #f0f8ff;

  --color-neutral-10: #191919;

  --color-primary-light: #ff3366;
  --color-primary-dark: #ba265d;

  --color-error: #861313;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f7f7f7;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --color-grey-dark-4: rgba(0, 0, 0, 0.15);
  --color-grey-dark-4-1: rgba(0, 0, 0, 0.23);
  --color-grey-dark-5: rgba(0, 0, 0, 0.05);
  --color-grey-dark-6: #e9eaed;
  --color-grey-dark-7: #e6e6e6;
  --color-grey-dark-8: rgba(0, 0, 0, 0.67);
  --color-middle-grey: #efefef;

  --color-project-nav: rgba(247, 247, 247, 1);
  --color-project-nav-active: rgba(239, 239, 239, 1);

  --color-text-primary: #4c4c4c;
  --color-text-secondary: rgba(0, 0, 0, 0.6);

  --color-nav-button-text: rgba(102, 102, 102, 102, 1);

  --color-image-overflow-box: rgba(0, 0, 0, 0.6);

  --color-content-background: #f6f9fc;

  --color-white: #fff;

  --color-tag-card: rgb(246, 249, 252);
  --color-tag-card-selected: rgb(204, 230, 255);

  // Tags
  --color-tag-selected-font: rgba(255, 255, 255);

  --color-tag-default-background: rgba(0, 0, 0, 0.1);
  --color-tag-default-font: rgba(0, 0, 0, 0.5);
  --color-tag-default-selected-background: rgba(0, 0, 0, 0.5);

  //Equipments
  --color-tag-equpments-background: rgba(229, 243, 255);
  --color-tag-equpments-font: rgba(0, 79, 153);
  --color-tag-equpments-selected-background: rgba(0, 79, 153);

  --color-tag-objects-background: rgba(245, 251, 248);
  --color-tag-objects-font: rgba(84, 122, 90);
  --color-tag-objects-selected-background: rgba(84, 122, 90);

  --color-tag-structures-background: rgba(245, 240, 255);
  --color-tag-structures-font: rgba(104, 50, 123);
  --color-tag-structures-selected-background: rgba(104, 50, 123);

  --color-tag-equipments-selected-background: rgba(24, 144, 255, 0.24);
  --color-tag-equipments-selected-border: rgba(24, 144, 255, 1);
  --color-tag-equipments-selected-dark: rgba(0, 72, 138, 1);

  --color-tag-objects-selected-background: rgba(88, 172, 102, 0.24);
  --color-tag-objects-selected-border: rgba(68, 136, 79, 1);
  --color-tag-objects-selected-dark: rgba(83, 122, 90, 1);

  --color-tag-structures-selected-background: rgb(245, 240, 255);
  --color-tag-structures-selected-border: rgba(104, 50, 123, 1);
  --color-tag-structures-selected-dark: rgba(104, 50, 123, 1);

  --color-tag-events-selected-background: rgb(217, 217, 217);
  --color-tag-events-selected-border: rgb(153, 153, 153);
  --color-tag-events-selected-dark: rgb(179, 179, 179);

  --color-icon-background: #e5f3ff;
  --color-icon-color: #001a33;

  --color-chart-primary: #008ffb;

  --box-shadow-right: inset 0px -1px 0px 1px rgba(100, 121, 143, 0.122);
  --box-shadow-bottom: inset 0px -1px 0px 0px rgba(100, 121, 143, 0.122);

  --color-upload-outline: #b3b3b3;
  --color-user-status-not-confirmed: #ff9800;
  --color-last-used-saved-search-icon: rgba(153, 153, 153, 1);
  --color-saved-search-icon: #333333;
  --color-saved-search-delete-bg: #deeefd;
  --color-saved-search-drop-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);

  --color-toaster-success-text: #112214;
  --color-toaster-error-text: #410002;
  --color-toaster-success-background: #ddeee0;
  --color-toaster-error-background: #ffdad6;
  --color-toaster-success-icon: #44884f;
  --color-toaster-error-icon: #861313;

  --color-default-mui-button-hover-bg: rgba(2, 136, 209, 0.04);

  --color-select-error: #d32f2f;

  --color-language-switcher-icon: #e0e0e0;

  --color-drag-row-icon: rgba(0, 0, 0, 0.08);
  --color-drag-row-icon-hovered: rgba(0, 0, 0, 0.56);

  --color-error: #d32f2f;
}
