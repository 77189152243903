.container {
  display: flex;
  padding: var(--spacing-16);
  flex-direction: column;
  justify-content: center;
  gap: var(--spacing-40);
  align-self: stretch;
  border-radius: var(--spacing-16);
  background: var(--color-grey-light-2);
}

.gridContainer {
  display: flex;
  gap: 16px;
}

.plots {
  display: grid;
  gap: 16px;
}
