.container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: var(--spacing-8) var(--spacing-16);
  border-radius: var(--spacing-16);
  opacity: 0.8;
  background: var(--color-grey-light-2);
}

.filters {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-16);
}

.accordionButton {
  &:global(.MuiButtonBase-root) {
    color: var(--color-primary-50);
    font-family: Poppins;
    font-size: var(--spacing-16);
    font-weight: 500;
    line-height: var(--spacing-16);
    letter-spacing: 0.5px;
    text-transform: capitalize;
    padding: 8px 10px;
  }
}
