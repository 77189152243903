.container {
  display: flex;
  padding: var(--spacing-16);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-32);
  align-self: stretch;
  border-radius: var(--spacing-6);
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  height: 190px;
  aspect-ratio: 1.6;
}

.title {
  color: var(--color-neutral-10);
  font-size: 14px;
  font-weight: 400;
  line-height: var(--spacing-20);
  letter-spacing: -0.28px;
}

.value {
  font-size: var(--spacing-32);
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.64px;
}

.primaryText {
  color: var(--color-primary-50);
}

.secondaryText {
  color: var(--color-neutral-10);
}

.graph {
  border-radius: var(--spacing-6);
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 100%;
  object-fit: contain;
  min-height: 400px;
  border: none;
}
