.error {
  color: var(--color-error);
  font-size: var(--spacing-12);
  line-height: var(--spacing-20); /* 19.92px */
  letter-spacing: 0.4px;
  position: absolute;
}

.container {
  position: relative;
}
