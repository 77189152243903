.label {
  display: flex;
  align-items: flex-end;
  gap: var(--spacing-4);
  width: max-content;
}

.icon {
  &:global(.MuiSvgIcon-root) {
    width: var(--spacing-10);
    height: var(--spacing-10);
  }
  color: #0000008f;
  margin-bottom: var(--spacing-6);
}

.flex {
  display: flex;
  gap: 0;
  align-items: center;
}

.dragIcon {
  color: var(--color-drag-row-icon);
  &Hovered {
    color: var(--color-drag-row-icon-hovered);
  }
}

.plusButton {
  &:global(.MuiButtonBase-root) {
    color: var(--color-primary);
    left: -20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
  }
}

.minusButton {
  &:global(.MuiButtonBase-root) {
    position: absolute;
    color: white;
    background-color: var(--color-error);
    left: -9px;
    bottom: 20px;
    cursor: pointer;
    height: 19px;
    width: 19px;
    border-radius: 999px;
    box-shadow: 0px 0px 6px gray;
    &:hover {
      background-color: var(--color-error);
    }
  }
}

.footerContainer {
  display: flex;
  padding: var(--spacing-16) var(--spacing-24) var(--spacing-16) 0px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px var(--spacing-8) var(--spacing-8);
  border-top: 1px solid #e0e0e0;
  position: relative;
}

.footerHeader {
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.17px;
}
.footerDesc {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
  line-height: var(--spacing-24);
  letter-spacing: 0.17px;
}
.tableHeaderCell {
  &:global(.MuiTableCell-root) {
    padding: var(--spacing-8);
  }

  &:nth-of-type(1) {
    padding-left: var(--spacing-24);
  }

  border-top: 1px solid #e0e0e0;
  text-align: left;
}

.tableCell {
  &:global(.MuiTableCell-root) {
    padding: var(--spacing-8);
  }
  border: none;
}

.emptySpace {
  font-size: 12px;
}
