.container {
  display: grid;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.title {
  color: #191919;
  &:global(.MuiTypography-root) {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.22px;
  }
}
