.tileHeader {
  padding: 14px 16px;
  border-radius: 8px;
  background: #f7f7f7;
  color: #191919;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.32px;
}

.accordianHeader {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.childHeader {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.expandIcon {
  transform: rotate(-90deg);
}

.filterButton {
  &:global(.MuiButton-root) {
    text-transform: none;
    color: #191919 !important;
    font-size: var(--spacing-16);
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.32px;
    background-color: #e5f3ff;
    padding: var(--spacing-12) var(--spacing-8) var(--spacing-12) var(--spacing-16);
    height: max-content;
    border-radius: var(--spacing-8);
  }
}

.applyButton {
  &:global(.MuiButton-root) {
    padding: var(--spacing-12) var(--spacing-16);
    border-radius: var(--spacing-6);
    text-transform: none;
  }
}
