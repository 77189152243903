.root {
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-16);
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: var(--spacing-8);
}

.label {
  color: var(--text-secondary, rgba(0, 0, 0, 0.6));
  &:global(.MuiTypography-root) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}




