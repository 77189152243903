.container {
  padding: var(--spacing-4) var(--spacing-12) var(--spacing-20);
  background: #f7f7f7;
  border-radius: var(--spacing-6) var(--spacing-6) 0 0;
}

.bodyContainer {
  padding: var(--spacing-12);
  border-bottom: 1px solid var(--color-grey-dark-7);
}

.labelItem {
  color: var(--color-grey-dark-1);
  font-size: var(--spacing-16);
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  display: flex;
}

.tableContainer {
  border: 1px solid var(--color-grey-dark-7);
  border-radius: var(--spacing-6);
}

.footerContainer {
  display: flex;
  padding: var(--spacing-8);
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: var(--color-grey-light-2);
  color: #000;
  font-size: var(--spacing-16);
  font-weight: 500;
  line-height: var(--spacing-22);
  letter-spacing: -0.32px;
}
