* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 100%; // 1rem = 10px, 10px/16px = 62.5%
  //font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: var(--color-text-primary);
  font-size: 1.8rem;

  min-height: 100vh;
}

a {
  text-decoration: none;
}
