.downloadButton {
  &:global(.MuiButtonBase-root) {
    color: #1890ff;
    text-transform: none;
    border-color: #808080;
  }
  border: 2;
  border-color: #808080;
  text-transform: none;
  color: #1890ff;
  border-radius: var(--spacing-8);
}
