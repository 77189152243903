.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: var(--spacing-24);
  height: var(--spacing-24);
  color: var(--color-language-switcher-icon);
}

.iconContainer {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.label {
  color: #eceff1;

  &:global(.MuiTypography-root) {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
  }
}

.item {
  &:global(.MuiMenuItem-root) {
    font-size: var(--spacing-16);
    line-height: var(--spacing-24);
    min-width: 250px;
  }
}

.labelDesc {
  &:global(.MuiTypography-root) {
    color: rgba(0, 0, 0, 0.6);
    font-size: var(--spacing-12);
    font-weight: 400;
    line-height: var(--spacing-20);
    letter-spacing: 0.4px;
  }
}
