.textContainer {
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
}

.container {
  display: flex;
  align-items: center;
  gap: 0;
}

.inputContainer {
  & input {
    color: #000000de;
    font-size: var(--spacing-20);
    font-weight: 500;
    line-height: var(--spacing-32);
    letter-spacing: 0.15px;
    padding: 0;
    margin-left: var(--spacing-4);
    width: 575px;
  }
}

.icon {
  cursor: pointer;
}
