.underlinedName {
  &:global(.MuiTableCell-root) {
    color: var(--color-primary-50);
    font-weight: 400;
    text-decoration-line: underline;
  }
}

.tableContainer {
  box-shadow: none;
  background: transparent;
  max-height: 450px;
}

.expanded {
  max-height: 650px;
}

.icon {
  &:global(.MuiSvgIcon-root) {
    height: 18px;
    width: 18px;
  }
  color: var(--color-primary-50);
}

.chipLabel {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.circleIcon {
  &:global(.MuiSvgIcon-root) {
    height: var(--spacing-12);
    width: var(--spacing-12);
  }
  color: var(--color-primary-50);
}

.tableCell {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-10);
}

.chip {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
}

.chipContainer {
  background-color: rgba(0, 0, 0, 0.04);
}
