.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-16);
  flex-shrink: 0;
}

.grid {
  display: flex;

  align-items: flex-end;
  gap: 10px;
}
