.searchInput {
  & {
    :global(.MuiInputBase-input) {
      padding: 10px 12px;
      font-size: var(--spacing-12);
      line-height: var(--spacing-12);
      font-weight: 400;
    }
  }
}
