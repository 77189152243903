:global(.toolbar){
  height: 40px!important;
  width: 40px!important;
  align-items: center!important;
  border-radius: 50% !important;
  justify-content: center!important;
  background: white!important;
  position: absolute!important;
  top: 42%!important;
  left: 97%!important;
}

:global(.toolbar .toolbar-menu span){
  display: none!important;
}

:global(.toolbar-group-division){
  display:  none!important;
}
