.errorLabel {
  font-size: var(--spacing-12);
  line-height: var(--spacing-20);
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: var(--color-select-error);
  margin: 0;
  margin-top: var(--spacing-4);
  padding-left: var(--spacing-2);
}

.icon {
  height: 24px;
  width: 24px;
}
