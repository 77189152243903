.container {
  border-radius: var(--spacing-8);
  background: var(--color-grey-light-2);
  padding: 10px 0;
  position: relative;
  object-fit: contain;
  min-width: 0;
  min-height: 0;
}

.image {
  max-height: calc(100% - 10px);
  max-width: calc(100% - 20px);
  object-fit: contain;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  margin-top: 200px;
}

.button {
  &:global(.MuiButtonBase-root.MuiButton-outlined:not(.Mui-disabled)) {
    border: 0;
    border-radius: var(--spacing-4);
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(var(--spacing-2));
    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  &:global(.MuiButtonBase-root) {
    min-width: 48px;
    border: 0;
    border-radius: var(--spacing-4);
    border: none;
    background: rgba(255, 255, 255, 0.7);
     backdrop-filter: blur(var(--spacing-2));

  }
  &:hover {
    background: rgba(255, 255, 255, 0.5);
 }

}

.icon {
  color: var(--color-primary-40);
}

.actions {
  display: flex;
  gap: var(--spacing-12);

  position: absolute;
  bottom: 20px;
  right: 10px;
}
