.chipContainer {
  padding: var(--spacing-6) var(--spacing-8) var(--spacing-6) var(--spacing-16);

  border-radius: var(--spacing-8);
  background: #d9d7d5;
}

.value {
  color: rgba(38, 38, 38, 0.7);
}

.text {
  color: #262626;
  font-size: var(--spacing-16);
  font-weight: 500;
  line-height: var(--spacing-24);
  letter-spacing: 0.15px;
}
