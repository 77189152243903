:global(.MuiTooltip-popper .MuiTooltip-tooltip) {
  font-size: 0.6rem;
  padding: var(--spacing-8) var(--spacing-12);

  ul {
    list-style-type: none;

    li:not(:last-child) {
      margin-bottom: var(--spacing-8);
    }
  }
}
