.root {
  text-transform: inherit;
  font-size: 1.4rem;

  &:global(.MuiButton-sizeSmall) {
    height: 3rem;
  }

  &:global(.MuiButton-sizeMedium) {
    font-size: 1.8rem;
    height: 4.8rem;
    padding: 0 2.4rem;
  }

  &:global(.MuiButton-text:not(.Mui-disabled)) {
    color: var(--color-primary);
  }

  &:global(.MuiButton-colorError) {
    color: var(--color-error);
    border-color: var(--color-error);
  }

  &:global(.MuiButton-contained:not(.Mui-disabled)) {
    background-color: var(--color-primary);
  }

  &:global(.MuiButton-outlined:not(.Mui-disabled)) {
    background-color: transparent;
    border: 2px solid var(--color-primary);
  }
}
