.searchInput {
  margin-top: 4px;

  & {
    :global(.MuiInputBase-input) {
      padding: 8px 12px;
      font-size: 16px;
      line-height: var(--spacing-12);
      font-weight: 400;
    }
  }
}
