.container {
  //font-size: 1.4rem;
  font-size: 1rem;
  border-radius: 1.4rem;
}

.toastBar {
  max-width: 50rem;

  &__success {
    background-color: var(--color-toaster-success-background);
    color: var(--color-toaster-success-text);
  }

  &__error {
    background-color: var(--color-toaster-error-background);
    color: var(--color-toaster-error-text);
  }
}

.closeBtn {
  color: inherit;

  >svg {
    font-size: 1.8rem;
  }
}

.icon {
  font-size: 1.8rem;

  &__success {
    color: var(--color-toaster-success-icon);
  }

  &__error {
    color: var(--color-toaster-error-icon);
  }
}
