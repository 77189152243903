@charset "UTF-8";

@import "variables";
@import "colors";
@import "base";


//
// All styles before Legacy, need to get rid
//
.scroller::-webkit-scrollbar, .ant-table-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.css-ihdtdm {
  display: none !important;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  display: none !important;
}
.scroller::-webkit-scrollbar-track-piece,
.ant-table-body::-webkit-scrollbar-track-piece {
  background-color: white;
}

.scroller::-webkit-scrollbar-thumb:vertical,
.ant-table-body::-webkit-scrollbar-thumb:vertical {
  height: 8px;
  background-color: #2563eb;
}

.scroller,
.ant-table-body {
  scrollbar-color: #2563eb white;
  scrollbar-width: thin;
}

.ant-typography {
  font-family: Inter, serif;
}

h2.ant-typography {
  font-weight: 500;
  line-height: 38px;
}

.ant-table {
  box-shadow:
          0 2px 4px -2px rgba(16, 24, 40, 0.06),
          0 4px 8px -2px rgba(16, 24, 40, 0.1);
}

.ant-table .ant-table-thead {
  background-color: #4c4c4c;
}

.ant-table .ant-table-thead > tr > th.ant-table-cell,
.ant-table .ant-table-thead > tr > td.ant-table-cell.ant-table-cell-scrollbar {
  color: white;
  line-height: 20px;
  font-family: Inter, serif;
  text-align: center;
  white-space: nowrap;
  box-shadow: none;
}

.ant-table .ant-table-thead > tr > th.ant-table-column-sort {
  background: #4c4c4c;
}

.ant-table .ant-table-thead > tr > th.ant-table-column-sort:hover {
  background: #4c4c4c;
}

.ant-table .ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
  background: #4c4c4c;
}

.ant-table .ant-table-thead > tr > th.ant-table-cell:before {
  display: none;
}

.ant-table .ant-table-thead > tr > th.ant-table-cell .ant-table-column-sorter .ant-table-column-sorter-up {
  color: white;
}

.ant-table .ant-table-thead > tr > th.ant-table-cell .ant-table-column-sorter .ant-table-column-sorter-down {
  color: white;
}

.ant-table .ant-table-thead > tr > th.ant-table-cell .ant-table-column-sorter .ant-table-column-sorter-up.active {
  visibility: visible;
}

.ant-table .ant-table-thead > tr > th.ant-table-cell .ant-table-column-sorter .ant-table-column-sorter-down.active {
  visibility: visible;
}

.ant-table .ant-table-thead .ant-table-filter-trigger {
  color: white;
  visibility: hidden;
}

.ant-table .ant-table-thead .ant-table-cell:hover .ant-table-filter-trigger, .ant-table .ant-table-thead .ant-table-cell .ant-table-filter-trigger.ant-dropdown-open, .ant-table .ant-table-thead .ant-table-cell .ant-table-filter-trigger.active {
  visibility: visible;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

.ant-table-tbody .ant-table-row:nth-child(odd) {
  background-color: #ededed;
}

.ant-table-tbody .ant-table-row .ant-table-cell {
  padding: 12px;
  text-align: center;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
}

.ant-table-tbody .ant-table-row .ant-table-cell.ant-table-column-sort {
  backdrop-filter: contrast(0.95);
}

.ant-btn {
  display: flex;
  align-items: center;
}

.ant-btn.ant-btn-sm {
  font-weight: 400;
}

.ant-btn.ant-btn-sm.ant-btn-icon-only {
  padding: 8px 16px;
}

.ant-slider .ant-slider-dot {
  border-width: 0;
}
.css-1wz20g3::before{
  background-color: unset !important
}

.ant-slider-mark {
  inset-inline-start: 0;
  top: -20px !important;
  width: 100%;
}
