.container {
  display: grid;
  gap: var(--spacing-24);
  position: relative;
}

.panel {
  overflow: hidden;
  display: grid;
  gap: var(--spacing-24);
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.panel.open {
  max-height: 750px;
  opacity: 1;
}
