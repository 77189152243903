.container {
  display: grid;
  padding: 8px 16px 16px 16px;
  gap: 8px;
  border-radius: 16px;
  opacity: 0.8;
  background: #f7f7f7;
}

.headerText {
  &:global(.MuiTypography-root) {
    color: #191919;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.22px;
  }
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.filters {
  display: flex;
  gap: 12px;
  align-items: flex-end;
}

.filterOption {
  width: 232px;
}

.submitButton {
  &:global(.MuiButtonBase-root) {
    text-transform: none;
    color: #fff;
    font-size: var(--spacing-16);
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.32px;
    width: 240px;
  }
}

.selectText {
  &:global(.MuiTypography-root) {
    color: #191919;
    font-size: var(--spacing-16);
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.32px;
  }
}

.filteredCount {
  color: var(--color-neutral-10);
  font-size: 14px;
  font-weight: 400;
  line-height: var(--spacing-20);
  letter-spacing: -0.28px;
}

.error {
  color: var(--color-error);
  cursor: pointer;
}
